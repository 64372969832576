import { render, staticRenderFns } from "./UpdateMessageTemplateView.vue?vue&type=template&id=24a9609f&scoped=true"
import script from "./UpdateMessageTemplateView.vue?vue&type=script&lang=ts"
export * from "./UpdateMessageTemplateView.vue?vue&type=script&lang=ts"
import style0 from "./UpdateMessageTemplateView.vue?vue&type=style&index=0&id=24a9609f&prod&lang=scss"
import style1 from "./UpdateMessageTemplateView.vue?vue&type=style&index=1&id=24a9609f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24a9609f",
  null
  
)

export default component.exports
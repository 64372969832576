import endpoints from '@/utils/endpoints.js'
import axios from '@/utils/init-axios.js'
import { Role, User } from '@/utils/interfaces'
import moment from 'moment'
import qs from 'qs'
import Vue from 'vue'
import { parse } from '../../../node_modules/vue-currency-input/src/index'
import { setIntegrationTypeCookie } from '@/utils/cookies'

export default {
  getMerchantCount({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(endpoints.listMerchantOrganizations())
        .then(res => {
          const numMerchants = res.data.count
          commit('setNumMerchants', { numMerchants })
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // TODO Needs to be set on refresh
  getMerchant({ commit, dispatch }, { merchantID }) {
    const appliedMerchantID =
      merchantID || localStorage.getItem('current_merchant_id')

    if (appliedMerchantID) {
      return new Promise((resolve, reject) => {
        axios
          .get(endpoints.retrieveMerchantOrganization(appliedMerchantID))
          .then(res => {
            const merchant = res.data
            setIntegrationTypeCookie(merchant.integration_type)
            commit('setCurrentMerchant', { merchant })
            dispatch('getLocations', merchant)
            dispatch('fetchMerchantAccounts', merchant)
            dispatch('getAccountTags')
            localStorage.setItem('current_merchant_id', merchant.id)
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        axios
          .get(endpoints.listMerchantOrganizations())
          .then(res => {
            const merchants = res.data.results

            if (res.data.count > 0) {
              const merchant = merchants[0]
              setIntegrationTypeCookie(merchant.integration_type)
              commit('setCurrentMerchant', { merchant })
              dispatch('getLocations', merchant)
              dispatch('fetchMerchantAccounts', merchant)
              dispatch('getAccountTags')
              localStorage.setItem('current_merchant_id', merchant.id)
            }
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    }
  },
  async getLocations({ commit }, merchant) {
    const response = await axios.get(
      endpoints.listMerchantLocations(merchant.id),
      {
        params: { page: 1, page_size: 1000 }
      }
    )

    const locations = response.data.results

    commit('setLocations', locations)
    commit('setCurrentLocation', locations[0])
  },
  async getAccountTags({ commit, getters }) {
    const response = await axios.get(
      endpoints.listAccountTags(getters.currentMerchant.id),
      {
        params: { page: 1, page_size: 40 }
      }
    )
    commit('setAccountTags', response.data?.results ?? [])
  },
  async getUsers({ commit }, { merchant, querystring }) {
    const response = await axios.get(endpoints.listUsers(merchant.id), {
      params: querystring
    })
    const users = response.data.results.map(user => new User(user))
    commit('setUsers', users)
  },
  getMerchantPage({ commit }, { params }) {
    commit('setMerchantLoading', true)
    return new Promise((resolve, reject) => {
      if ('search' in params) {
        params['search'] = params['search'].trim()
      }
      axios
        .get(endpoints.listMerchantOrganizations(), {
          params: params
        })
        .then(res => {
          const numMerchants = res.data.count
          const merchants = res.data.results
          commit('setNumMerchants', { numMerchants })
          commit('setMerchantPage', { merchants })
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getMerchantReportURL({ commit, state }, { reportType }) {
    commit('setTransactionReportURL', { reportURL: '' })
    return new Promise((resolve, reject) => {
      axios
        .get(endpoints.listtransaction_report_urls(), {
          params: {
            account_id: state.currentMerchantAccount.id,
            report_type: reportType
          }
        })
        .then(res => {
          const reportURL = res.data.url
          commit('setTransactionReportURL', { reportURL })
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  setPageTitle({ commit }, pageTitle) {
    commit('setPageTitle', [...pageTitle])
  },
  getOauthLinks({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(endpoints.listOauthLinks())
        .then(res => {
          const oauthLinks = res.data
          commit('setOauthLinks', oauthLinks)
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  async getCustomer(
    { state, commit },
    { customerID, checkIntegration = false }
  ) {
    const merchantID = state.currentMerchant.id
    try {
      commit('setCustomerLoading', true)
      const response = await axios.get(
        endpoints.retrieveCustomer(merchantID, customerID),
        {
          params: { checkIntegration: checkIntegration }
        }
      )
      const customer = response.data
      commit('setCurrentCustomer', customer)

      axios.post(endpoints.updateLastViewed(merchantID, customerID))

      return customer
    } finally {
      commit('setCustomerLoading', false)
    }
  },
  async getFailedActions({ commit }, { merchantID, page_size = 100 }) {
    const response = await axios.get(endpoints.listFailedActions(merchantID), {
      params: { page_size }
    })
    const failedActions = response.data.results
    commit('setFailedActions', failedActions)

    return failedActions
  },
  async markActionResolved(
    { dispatch },
    { merchantID, actionID, refresh = false }
  ) {
    const response = await axios.post(
      endpoints.resolveFailedAction(merchantID, actionID)
    )
    Vue.toasted.show('Marked Action as Resolved', {
      duration: 2000,
      position: 'bottom-center'
    })
    if (refresh) {
      dispatch('getFailedActions', { merchantID })
    }
    return response
  },
  async retryAction({ dispatch }, { merchantID, actionID, refresh = false }) {
    const response = await axios.post(
      endpoints.retryFailedAction(merchantID, actionID)
    )
    Vue.toasted.show('Succesfully Retried and Resolved Action', {
      duration: 2000,
      position: 'bottom-center'
    })
    if (refresh) {
      dispatch('getFailedActions', { merchantID })
    }
    return response
  },
  async openReceiptModal({ commit }, payment) {
    try {
      commit('setCurrentPayment', payment)
      // If no errors fetching open modal
      commit('setReceiptModalVisibility', true)
    } catch {
      // pass
    }
  },
  async getTransactions({ state }, { page, size, statuses, range, like }) {
    const merchantID = state.currentMerchant.id
    const filteredStatuses = statuses
      .filter(status => status.selected)
      .map(status => status.value)
    const response = await axios.get(
      endpoints.listTransactionReport(merchantID),
      {
        params: {
          page: page,
          page_size: size,
          status: filteredStatuses,
          start: range.start,
          end: range.end,
          like: like
        },
        paramsSerializer: params => {
          return qs.stringify(params, { arrayFormat: 'repeat' })
        }
      }
    )
    return response
  },
  async getTransactionExport({ state }, { page, size, range, statuses, like }) {
    const merchantID = state.currentMerchant.id
    const filteredStatuses = statuses
      .filter(status => status.selected)
      .map(status => status.value)
    const response = await axios.post(
      endpoints.exportTransactionReport(merchantID),
      {
        params: {
          page_number: page,
          page_size: size,
          status: filteredStatuses,
          start: range.start,
          end: range.end,
          like: like
        },
        paramsSerializer: function (params) {
          return qs.stringify(params, { arrayFormat: 'repeat' })
        }
      }
    )
    return response
  },
  async refundPayment({ state }, { payment, total, sources }) {
    const merchantID = state.currentMerchant.id
    const response = await axios.post(endpoints.refundPayment(merchantID), {
      payment: payment,
      total: total,
      sources: sources
    })
    return response
  },
  async cancelPayment({ state }, { paymentID }) {
    const merchantID = state.currentMerchant.id
    const response = await axios.post(endpoints.cancelPayment(merchantID), {
      payment: paymentID
    })
    return response
  },
  async refundTransaction({ state }, { transactionID }) {
    const merchantID = state.currentMerchant.id
    const response = await axios.post(endpoints.refundTransaction(merchantID), {
      transaction: transactionID
    })
    return response
  },
  async fetchAccountPromises({ commit, state }) {
    if (!state?.currentMerchant?.id || !state?.currentAccount?.id) return
    commit('setAccountPromisesLoading', true)
    const response = await axios.get(
      endpoints.getAccountPromises(
        state.currentMerchant.id,
        state.currentAccount.id
      )
    )

    const promises = response.data.filter(
      promise => moment(promise.scheduled_date).diff(moment()) > 1
    )

    commit('setAccountPromises', promises)
    commit('setAccountPromisesLoading', false)
  },
  async fetchMerchantAccounts({ commit }, { id }) {
    commit('setMerchantAccountsLoading', true)
    const response = await axios.get(endpoints.listMerchantAccounts(id))
    commit('setMerchantAccounts', response.data)
    commit('setCurrentMerchantAccount', response.data.results[0])
    commit('setMerchantAccountsLoading', false)
    return response
  },
  async fetchBinInfo({ commit }, { merchantID, bin }) {
    commit('setBinInfoLoading', true)
    const response = await axios.get(endpoints.retrieveBinInfo(merchantID, bin))
    commit('setBinInfo', response.data)
    commit('setBinInfoLoading', false)
    return response
  },
  async fetchRole({ commit }, { merchantID, roleID }) {
    commit('setRoleLoading', true)
    const response = await axios.get(endpoints.roleDetail(merchantID, roleID))
    const role = new Role()
    role.description = response.data.role_description
    role.name = response.data.role_name
    role.settings = response.data.role_settings
    role.settings.max_merchant_payment_amount = parse(
      role.settings.max_merchant_payment_amount,
      {}
    )
    role.settings.min_merchant_payment_amount = parse(
      role.settings.min_merchant_payment_amount,
      {}
    )
    for (const permission in role.permissions) {
      const each = response.data.has_access.find(has => {
        return permission === has.name
      })
      if (each) role.permissions[permission] = true
    }
    commit('setRole', role)
    commit('setRoleLoading', false)
  },
  // eslint-disable-next-line
  async getMostRecentPayment(
    { commit },
    { merchantID, customerID, accountID }
  ) {
    commit('setRecentPaymentLoading', true)
    const response = await axios.get(
      endpoints.listCustomerAccountTransactions(
        merchantID,
        customerID,
        accountID
      ),
      { params: { page: 1, page_size: 50 } }
    )
    const defaultDateFormat = 'YYYY-MM-DD'
    const currentDate = moment().format(defaultDateFormat)
    // filter the payments for the current date and status PAID sorted by created desc
    const data = response.data?.results
      .filter(item => {
        return (
          ['PAID', 'REFUND'].includes(item.status) &&
          moment(item.created).format(defaultDateFormat) === currentDate
        )
      })
      .sort(
        (before, after) =>
          new Date(moment(before.created)) - new Date(moment(after.created))
      )
      .map(item => {
        return {
          created: item.created,
          id: item.id,
          total_amount: item.payment.total_amount
        }
      })
    commit('setRecentPaymentLoading', false)

    if (!data.length) return {}
    return data[0]
  },
  async voidCustomer({ state, commit }, customerID) {
    const merchantID = state.currentMerchant.id
    try {
      commit('setCustomerLoading', true)
      const response = await axios.delete(
        endpoints.voidCustomer(merchantID, customerID)
      )
      return response
    } finally {
      commit('setCustomerLoading', false)
    }
  },
  getDashboardDateRange({ commit }, dateRange) {
    commit('setDashboardDateRange', dateRange)
  },
  async getAvailableUpc() {
    const response = await axios.get(endpoints.listAvailableUpc(), {})
    return response
  },
  async getPropayBusinessTypes() {
    const response = await axios.get(endpoints.listPropayBusinessTypes(), {})
    return response
  }
}


















































































import Vue from 'vue'
import BlytzDatePicker from '@/components/BlytzDatePicker/BlytzDatePicker.vue'
import SwitchInput from '@/components/SwitchInput/SwitchInput.vue'
import DataTablePagination from '@/components/DataTable/DataTablePagination.vue'
import InfoIcon from '@/Icons/InfoIcon.vue'

export interface Action {
  label: string
  outline: boolean
  action: (selected: any) => void
  requireSelection: boolean
}

export interface TableData {
  headers: { key: string; label: string }[]
  items: { [key: string]: string }[]
}

export interface Filter {
  label: string
  type: 'date' | 'toggle'
  props?: any
  onFilter: (value: string) => void
}

export default Vue.extend({
  name: 'DataTable',
  components: {
    DataTablePagination,
    InfoIcon,
    BlytzDatePicker,
    SwitchInput
  },
  props: {
    selectable: {
      type: Boolean,
      default: false
    },
    showDetails: {
      type: Boolean,
      default: false
    },
    onShowDetails: {
      type: Function,
      default: () => false
    },
    title: {
      type: String,
      default: ''
    },
    actions: {
      type: Array as () => Action[],
      default: (selected: any) => [] as Action[]
    },
    onSortChanged: {
      type: Function,
      default: () => false
    },
    onRowClicked: {
      type: Function,
      required: false,
      default: () => false
    },
    filters: {
      type: Array as () => Filter[],
      default: () => [] as Filter[]
    },
    hasDateFilter: {
      type: Boolean,
      default: false
    },
    onDateFilter: {
      type: Function,
      default: (dateRange: string) => false
    },
    tableData: {
      type: Object as () => TableData,
      default: () => ({ headers: [], items: [] })
    },
    onPageChange: {
      type: Function,
      default: (page: number) => false
    },
    onPageSizeChange: {
      type: Function,
      default: (size: number) => false
    },
    currentPage: {
      type: Number,
      default: 1
    },
    pageSize: {
      type: Number,
      default: 10
    },
    totalRecords: {
      type: Number,
      default: 0
    },
    paginated: {
      type: Boolean,
      default: true
    },
    className: {
      type: String,
      default: ''
    }
  },
  data(): {
    selected: any[]
    startDate: Date
    endDate: Date
  } {
    return {
      selected: [] as any[],
      // start date 7 days ago. End date today
      startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
      endDate: new Date()
    }
  },
  computed: {
    fields(): { key: string; label: string }[] {
      const { headers } = this.tableData as TableData
      this.showDetails && headers.push({ key: 'details', label: 'Details' })
      this.selectable && headers.unshift({ key: 'checkbox', label: '' })
      return headers
    },
    allRowsSelected(): boolean {
      return (
        (this.selected as []).length ===
        (this.tableData as TableData).items.length
      )
    },
    isClickable(): boolean{
      return this.onRowClicked !== false
    }
  },
  methods: {
    onRowSelected(items: any[]) {
      this.$data.selected = items
    },
    dateChange(e) {
      console.log('change', e.target.checked)
    }
  }
})

import axios from 'axios'
import store from '@/store'
import { i18n } from '@/locales/i18n-setup'

axios.defaults.baseURL = window
  ? window.VUE_APP_API_URL
  : process.env.VUE_APP_API_URL

function translateResponse(response) {
  const translated = _translate(response)
  return translated
}

function _translate(item) {
  if (typeof item === 'string' || item instanceof String) {
    return i18n.t(item)
  } else if (item instanceof Array) {
    return item.map(subItem => _translate(subItem))
  } else if (item instanceof Object) {
    return Object.fromEntries(
      Object.entries(item).map(([key, value]) => [key, _translate(value)])
    )
  } else {
    return item
  }
}

axios.interceptors.response.use(
  response => {
    if (!response.data.bypassRefresh) {
      store.commit('setLastActivity')
    }

    return response
  },
  error => {
    if (error?.response?.status === 401) {
      store.dispatch('logout')
    } else {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      console.log('error', error)
      error.response.data = translateResponse(error.response.data)
    }

    return Promise.reject(error)
  }
)

axios.interceptors.request.use(config => {
  if (
    !store?.state?.dataStore?.currentMerchant ||
    !store.state.dataStore.currentMerchant.integration_type
  ) {
    return config
  }
  const integrationType =
    store?.state?.dataStore?.currentMerchant?.integration_type
  if (integrationType) {
    config.headers = {
      'X-Blytz-Integration-Type': integrationType,
      ...config.headers
    }
  }
  return config
})

export default axios

export const anonymousAxios = axios.create({
  baseURL: axios.defaults.baseURL,
  transformRequest: [
    (data, headers) => {
      // or just the auth header
      delete headers.common.Authorization
      return data
    },
    ...axios.defaults.transformRequest
  ]
})

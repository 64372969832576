import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export class DiscardDraftConfirmation extends Vue {
  draftModalOpen = false

  async discardDraftConfirmation() {
    return new Promise(resolve => {
      const response = this.$bvModal.msgBoxConfirm(
        'Changes you made may not be saved.',
        {
          title: 'Leave Page?',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'blue',
          okTitle: 'Leave',
          cancelTitle: 'Cancel',
          cancelVariant: 'transparent',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        }
      )
      resolve(response)
    })
  }
}

@Component
export class OptinVerification extends Vue {
  async optinVerification(optin) {
    const media = optin.media === 'EMAIL' ? 'emails' : 'text messages'
    const status = optin.status ? 'on' : 'off'

    return new Promise(resolve => {
      const response = this.$bvModal.msgBoxConfirm(
        'By clicking "I\'m Sure" you affirm that you have express permission from the customer to turn ' +
          media +
          ` ${status}`,
        {
          title: 'Are you sure?',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'blue',
          okTitle: "I'm Sure",
          cancelTitle: 'Cancel',
          cancelVariant: 'transparent',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        }
      )
      resolve(response)
    })
  }
}

@Component
export class DisableVerification extends Vue {
  async disableVerification(user, isCurrent) {
    const fullName = `${user.first_name} ${user.last_name}`
    const header = `Are you sure you would like to disable ${fullName}? `
    const body = isCurrent
      ? "This is your current user, doing so will remove your roles, log you out and you'll be prevented from logging in."
      : 'Doing so will remove their roles and prevent them from logging in.'

    return new Promise(resolve => {
      const response = this.$bvModal.msgBoxConfirm(header + body, {
        title: 'Are you sure?',
        size: 'med',
        buttonSize: 'sm',
        okVariant: 'red',
        okTitle: "I'm Sure",
        cancelTitle: 'Cancel',
        cancelVariant: 'transparent',
        footerClass: 'p-2',
        hideHeader: true,
        centered: true
      })
      resolve(response)
    })
  }
}

@Component
export class VoidCustomerConfirmation extends Vue {
  async voidCustomerConfirmation(customerName) {
    return new Promise(resolve => {
      const response = this.$bvModal.msgBoxConfirm(
        `Are you sure you would like to remove ${customerName}?`,
        {
          dialogClass: 'void-customer-modal',
          title: 'Are you sure?',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: "Yes, I'm sure",
          cancelTitle: 'No',
          cancelVariant: 'transparent',
          footerClass: 'p-2',
          hideHeader: true,
          hideHeaderClose: false,
          centered: true
        }
      )
      resolve(response)
    })
  }
}

@Component
export class IntegrationDisableConfirmation extends Vue {
  async confirmDisableIntegration() {
    return new Promise(resolve => {
      const response = this.$bvModal.msgBoxConfirm(
        "You are about to remove this merchant's integration. This may impact payment processing.",
        {
          title: 'Confirm Disable Integration',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Disable Integration',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-secondary',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        }
      )
      resolve(response)
    })
  }
}

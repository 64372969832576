import Cookies from 'js-cookie'

const INTEGRATION_TYPE_COOKIE_NAME = 'integration_type'
export function setIntegrationTypeCookie(integrationType: string) {
  const currentIntegrationType = Cookies.get(INTEGRATION_TYPE_COOKIE_NAME)
  if (currentIntegrationType !== integrationType) {
    Cookies.set(INTEGRATION_TYPE_COOKIE_NAME, integrationType, {
      secure: window.VUE_APP_ENV !== 'localhost',
      sameSite: 'Lax',
      expires: 1
    })
  }
}

export function clearIntegrationTypeCookie() {
  Cookies.remove(INTEGRATION_TYPE_COOKIE_NAME)
}

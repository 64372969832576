












































import NavFirstIcon from '@/Icons/NavFirstIcon.vue'
import NavLastIcon from '@/Icons/NavLastIcon.vue'
import NavNextIcon from '@/Icons/NavNextIcon.vue'
import NavPrevIcon from '@/Icons/NavPrevIcon.vue'
import Vue from 'vue'

export default Vue.extend({
  name: 'DataTablePagination',
  components: {
    NavFirstIcon,
    NavLastIcon,
    NavNextIcon,
    NavPrevIcon
  },
  props: {
    pageSizeOptions: {
      type: Array,
      default: () => [10, 20, 50]
    },
    currentPage: {
      type: Number,
      default: 1
    },
    totalRecords: {
      type: Number,
      default: 0
    },
    pageSize: {
      type: Number,
      default: 10
    },
    onPageChange: {
      type: Function,
      required: true
    },
    onPageSizeChange: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      currentPageSize: 10
    }
  },
  methods: {
    firstPage() {
      const newPage = 1
      this.onPageChange(newPage)
      return 1
    },
    prevPage() {
      const newPage = this.currentPage > 1 ? this.currentPage - 1 : 1
      this.onPageChange(newPage)
      return newPage
    },
    nextPage() {
      const lastPage = Math.ceil(this.totalRecords / this.pageSize)
      const newPage =
        this.currentPage < lastPage ? this.currentPage + 1 : lastPage
      this.onPageChange(newPage)
      return newPage
    },
    lastPage() {
      const newPage = Math.ceil(this.totalRecords / this.pageSize)
      this.onPageChange(newPage)
      return newPage
    }
  }
})

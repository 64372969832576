import { bypassRefreshResponse } from '@/mixins/RequestMixins/RequestMixins.js'
import { eventBus } from '@/plugins/eventbus.ts'
import router from '@/router'
import endpoints from '@/utils/endpoints.js'
import axios, { anonymousAxios } from '@/utils/init-axios.js'
import moment from 'moment'
import { clearIntegrationTypeCookie } from '@/utils/cookies'

const timeoutSeconds = 60 * process.env.VUE_APP_SESSION_TIMEOUT
const idleSeconds = 60 * process.env.VUE_APP_SESSION_IDLE

export default {
  async login({ commit, dispatch }, credentials) {
    const endpoint =
      credentials.id && credentials.token
        ? endpoints.SSOAuth()
        : endpoints.createBlytzTokenObtainPair()

    const response = await anonymousAxios.post(endpoint, credentials)
    const data = response.data

    const accessToken = data.access
    const refreshToken = data.refresh

    localStorage.setItem('blytzpay_access_token', accessToken)
    localStorage.setItem('blytzpay_refresh_token', refreshToken)
    commit('setTokens', { accessToken, refreshToken })

    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`

    await dispatch('heartbeat')
  },

  async logout({ state, commit, getters }) {
    eventBus.emit('clearInterval', {})

    try {
      if (getters.isAuthenticated) {
        await axios.post(endpoints.blacklistToken(), {
          refresh_token: state.refreshToken
        })
      }
    } catch {
      // If there was a problem, it's meaningless to everyone, so
      // don't let them escape to Sentry
    } finally {
      clearInterval(state.heartbeat)
      localStorage.removeItem('current_merchant_id')
      commit('setHeartbeat', null)
      commit('setTokens', { accessToken: null, refreshToken: null })
      commit('setIsInactive', false)
      commit('resetState')
      clearIntegrationTypeCookie()
      axios.defaults.headers.common = {}

      router.push({ name: 'Login' })
    }
  },

  async heartbeat({ commit, state, dispatch }) {
    if (state.heartbeat) return

    const heartbeat = setInterval(() => {
      dispatch('authHeartbeat')
    }, process.env.VUE_APP_HEARTBEAT_MS)

    commit('setHeartbeat', heartbeat)
  },

  async refreshAccess({ commit, state, getters, dispatch }) {
    if (!state.refreshToken) return

    const claims = getters.refreshClaims
    const now = moment().utc().unix()

    if (now < claims?.exp) {
      try {
        const response = await axios.post(
          endpoints.createTokenRefresh(),
          {
            refresh: state.refreshToken
          },
          { transformResponse: [bypassRefreshResponse] }
        )

        const data = response.data

        commit('setTokens', {
          accessToken: data.access,
          refreshToken: data.refresh || state.refreshToken
        })

        axios.defaults.headers.common['Authorization'] = `Bearer ${data.access}`
        return response
      } catch (error) {
        dispatch('logout')
        return error
      }
    }
  },

  // Handles idle logout. Should be called every 15 seconds or so
  async authHeartbeat({ commit, getters, state, dispatch }) {
    // If we are not authenticated, then bail
    if (!getters.isAuthenticated) return

    const now = moment().utc().unix()
    const sinceLastActivity = now - state.lastActivity
    const idle = idleSeconds < sinceLastActivity

    if (timeoutSeconds < sinceLastActivity) {
      // Log out if session has timed out
      dispatch('logout')
    } else if (idleSeconds < sinceLastActivity && state.isInactive == false) {
      commit('setIsInactive', idle)
    } else if (
      0 < getters.refreshTokenTimeToLive() &&
      getters.accessTokenTimeToLive() < 180
    ) {
      /*
        Refresh access if following conditions are met
        - Refresh token is valid
        - Access token has less than 50 seconds to live
      */
      dispatch('refreshAccess')
    }
  },

  // Called when the user chooses to continue their session
  continueSession({ dispatch, commit }) {
    commit('setIsInactive', false)
    commit('setLastActivity')
    dispatch('refreshAccess')
  },

  async getProfile({ commit }) {
    const response = await axios.get(endpoints.listProfiles())
    const profile = response?.data?.results[0]

    commit('setProfile', profile)
    return response
  }
}
